'use client'

import React from 'react'
import { useTheme, Typography, Container, Box } from '@mui/material'
import Image from './Image'

const data = {
  title: 'Easily swap your existing units with engaging projects',
  description:
    'Mapped to existing standards and curriculum you already use, like Illustrative Math and OpenSciEd.',
  tag: 'Replacement Units',
}

const Units = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        pb: { sm: 6, lg: 23 },
      }}
    >
      <Container>
        <Box
          sx={{
            mb: 12,
            display: 'flex',
            justifyContent: 'right',
            position: 'relative',
            flexDirection: { xs: 'column-reverse', sm: 'row' },
          }}
        >
          <Box
            sx={{
              width: { xs: '100%' },
              [theme.breakpoints.up('xl')]: {
                width: 710,
              },
              [theme.breakpoints.up('sm')]: {
                position: 'absolute',
                width: '60vw',
                left: -100,
              },
            }}
          >
            <Image
              alt="Ready-made PBL units"
              height="516"
              sizes="(max-width: 600px) 100vw,(max-width: 1536px) 60vw, 710px"
              src="units"
              style={{
                height: 'auto',
                maxWidth: '100%',
              }}
              width="740"
            />
          </Box>
          <Box
            sx={{
              maxWidth: { sm: 314, lg: 345 },
              pt: { sm: 6, lg: 15 },
              mr: { lg: 15 },
            }}
          >
            <Box
              sx={{
                display: 'inline-block',
                color: '#000',
                py: 1,
                px: 1.5,
                border: '1.5px solid #000',
                borderRadius: 2,
                fontSize: 16,
                fontWeight: 700,
                mb: 2.5,
                bgcolor: 'purple',
              }}
            >
              {data.tag}
            </Box>
            <Typography
              sx={{
                fontSize: { xs: 24, lg: 36 },
                lineHeight: { xs: '32px', lg: '40px' },
              }}
              variant="h2"
            >
              {data.title}
            </Typography>
            <Typography
              sx={{ my: 2.5, fontSize: { xs: 18, lg: 20 } }}
              variant="body1"
            >
              {data.description}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Units
