'use client'

import React from 'react'
import { Typography, Container, Box, CardMedia, useTheme } from '@mui/material'
import Image from './Image'

const data = {
  title: 'Use as is or make it your own',
  description:
    'Customize all lesson slide decks and student materials in Google Drive.',
  curvedLine: './images/curved-line.svg',
  tag: 'Personalize Lessons',
}

const Lessons = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        pb: { xs: 3, md: 15, lg: 25 },
      }}
    >
      <Container sx={{ px: { xs: 2.5, sm: 4, lg: 3 } }}>
        <Box
          sx={{
            mb: 12,
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ maxWidth: { sm: 302, lg: 430 }, pt: 10 }}>
            <Box
              sx={{
                display: 'inline-block',
                color: '#000',
                py: '5px',
                px: '11px',
                border: '1.5px solid #000',
                borderRadius: 2,
                fontSize: 16,
                fontWeight: 700,
                mb: 2.5,
                bgcolor: 'green',
              }}
            >
              {data.tag}
            </Box>

            <Typography
              sx={{
                fontSize: { xs: 24, lg: 36 },
                lineHeight: { xs: '32px', lg: '40px' },
              }}
              variant="h2"
            >
              {data.title}
            </Typography>
            <Typography
              sx={{ my: 2.5, fontSize: { xs: 18, lg: 20 } }}
              variant="body1"
            >
              {data.description}
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: '100%' },
              [theme.breakpoints.up('xl')]: {
                width: 824,
              },
              [theme.breakpoints.up('sm')]: {
                position: 'absolute',
                width: '60vw',
                right: -150,
              },
            }}
          >
            <Image
              alt="A great collection of lessons"
              height="960"
              sizes="(max-width: 600px) 100vw,(max-width: 1536px) 60vw, 824px"
              src="lessons"
              style={{
                height: 'auto',
                maxWidth: '100%',
              }}
              width="1656"
            />
          </Box>
          <CardMedia
            alt="Curved Line"
            component="img"
            image={data.curvedLine}
            sx={{
              display: { xs: 'none', lg: 'block' },
              width: 824,
              position: 'absolute',
              top: 220,
              left: 270,
              zIndex: -1,
            }}
          />
        </Box>
      </Container>
    </Box>
  )
}

export default Lessons
